/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostKacFileUploadBodyFieldsUploadType =
  (typeof PostKacFileUploadBodyFieldsUploadType)[keyof typeof PostKacFileUploadBodyFieldsUploadType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostKacFileUploadBodyFieldsUploadType = {
  TEACHER_PROFILE_IMAGE: 'TEACHER_PROFILE_IMAGE',
  ACADEMY_MAIN_BANNER_IMAGE_PC: 'ACADEMY_MAIN_BANNER_IMAGE_PC',
  ACADEMY_MAIN_BANNER_IMAGE_MOBILE: 'ACADEMY_MAIN_BANNER_IMAGE_MOBILE',
  ACADEMY_INFO_IMAGE: 'ACADEMY_INFO_IMAGE',
  ACADEMY_TEACHER_IMAGE: 'ACADEMY_TEACHER_IMAGE',
  ETC: 'ETC',
} as const
