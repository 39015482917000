import { FC } from 'react'

import {
  EndOfSvg,
  ICON_SVG_DYNAMIC_IMPORT,
} from '@shared/lib/constants/import/icon-svg'

/**
 * line icon 은 상위 color 컬러 적용
 * fill icon 은 fill, stroke 컬러 적용
 *
 * React does not recognize the `iconSvg` prop on a DOM element.
 * If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `iconsvg` instead.
 * If you accidentally passed it from a parent component, remove it from the DOM element.
 * 로 인해 iconsvg로 변경할게요.
 */
interface IconProps {
  fill?: string
  stroke?: string
  id?: string
  className?: string
  icon: EndOfSvg
  onClick?: () => void
}

export const Icon: FC<IconProps> = (props) => {
  const { icon, ...svgProps } = props
  const IconSVG = ICON_SVG_DYNAMIC_IMPORT[icon]

  return <IconSVG {...svgProps} />
}
