import toast from 'react-hot-toast'
import styled from '@emotion/styled'

import { Icon } from '@shared/lib/components/common/atom/icon'
import { ICON_SVG } from '@shared/lib/constants/import/icon-svg'

export const TOAST_TYPE = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
} as const

export type ToastType = (typeof TOAST_TYPE)[keyof typeof TOAST_TYPE]

export const openToast = (toastType: ToastType, message: string) => {
  return toast.custom((t) => (
    <ToastContainer>
      {toastType === TOAST_TYPE.ERROR && (
        <ErrorIcon icon={ICON_SVG.LINE.SIZE_24.ICON_WARNING} />
      )}

      {toastType === TOAST_TYPE.SUCCESS && (
        <SuccessIcon icon={ICON_SVG.LINE.SIZE_24.ICON_CHECKED} />
      )}
      <span>{message}</span>
      <CloseIcon icon={ICON_SVG.LINE.SIZE_16.ICON_CLOSE} />
    </ToastContainer>
  ))
}

const ToastContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 16px;
  border-radius: 4px;
  width: 100%;
  max-width: 720px;

  background: ${({ theme }) => theme.colors.environment.background.default};
  box-shadow: 0 2px 8px 3px rgba(51, 51, 51, 0.2);

  span {
    white-space: pre-line;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.colors.brand.primary};
  }

  ${({ theme }) => theme.media.tabletSm} {
    min-width: auto;
  }
`

const ErrorIcon = styled(Icon)`
  flex-shrink: 0;
  width: 24px;

  rect {
    fill: ${({ theme }) => theme.colors.state.error.default};
  }

  circle {
    &:first-of-type {
      stroke: ${({ theme }) => theme.colors.state.error.default};
    }

    &:last-of-type {
      fill: ${({ theme }) => theme.colors.state.error.default};
    }
  }
`

const SuccessIcon = styled(Icon)`
  flex-shrink: 0;
  width: 24px;

  path {
    stroke: ${({ theme }) => theme.colors.state.success.default};
  }
`

const CloseIcon = styled(Icon)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.icon.active.black};
`
