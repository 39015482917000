export const Z_INDEX_ELEMENT = {
  HEADER: 'HEADER',
  HAMBURGER_MENU_CONTAINER: 'HAMBURGER_MENU_CONTAINER',

  DROP_DOWN_CONTAINER: 'DROP_DOWN_CONTAINER',
  DROP_DOWN_POPOVER_CONTAINER: 'DROP_DOWN_POPOVER_CONTAINER',
  MULTI_SELECT_CONTAINER: 'MULTI_SELECT_CONTAINER',

  HEADER_DROP_DOWN_CONTAINER: 'HEADER_DROP_DOWN_CONTAINER',

  MODAL_OVERLAY: 'MODAL_OVERLAY',
} as const

export const Z_INDEX_VALUE = {
  [Z_INDEX_ELEMENT.DROP_DOWN_CONTAINER]: 10,
  [Z_INDEX_ELEMENT.MULTI_SELECT_CONTAINER]: 10,

  [Z_INDEX_ELEMENT.HEADER]: 20,
  [Z_INDEX_ELEMENT.HEADER_DROP_DOWN_CONTAINER]: 21,
  [Z_INDEX_ELEMENT.MODAL_OVERLAY]: 30,
  [Z_INDEX_ELEMENT.DROP_DOWN_POPOVER_CONTAINER]: 40,
  [Z_INDEX_ELEMENT.HAMBURGER_MENU_CONTAINER]: 50,
}

export const HEADER_HEIGHT = {
  PC: 110,
  TABLET: 60, //TODO: 모바일 디자인 밖에 없기 때문에 태블릿 디자인 생기면 변경
  MOBILE: 60,
}

export const FOOTER_HEIGHT = {
  PC: 398,
  TABLET: 638, //TODO: 모바일 디자인 밖에 없기 때문에 태블릿 디자인 생기면 변경
  MOBILE: 638,
}

export const MAX_WIDTH = {
  INNER_CONTAINER: {
    PC: 1200,
    TABLET: 1024,
    TABLET_SM: 768,
    MOBILE: 360,
  },
}

export const LAYOUT_PADDING = {
  VERTICAL: {
    PC: 24,
  },
  HORIZONTAL: {
    PC: 80,
    TABLET: 24,
    MOBILE: 20,
  },
} as const

export const BRANCH_FILTER_HEIGHT = {
  PC: 61,
  TABLET: 50,
  MOBILE: 50,
}
