import styled from '@emotion/styled'

import { Icon } from '@shared/lib/components/common/atom/icon'
import { ICON_SVG } from '@shared/lib/constants/import/icon-svg'

export const SearchField = () => {
  return (
    <Search>
      <button>
        <SearchIcon icon={ICON_SVG.LINE.SIZE_24.ICON_SEARCH} />
      </button>
      <input placeholder={'나에게 맞는 강의를 찾아보세요.'} />
    </Search>
  )
}

const SearchIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  path {
    stroke: ${({ theme }) => theme.colors.icon.disabled.white};
  }
`

const Search = styled.div`
  position: relative;

  display: flex;
  width: 290px;
  height: 36px;
  align-items: center;
  gap: 8px;

  input {
    width: 100%;
    height: 100%;
    padding-left: calc(16px + 24px);

    border: 1px solid transparent;
    border-radius: 40px;
    background: ${({ theme }) => theme.colors.brand.white.disabled};

    ${({ theme }) => theme.typography.body1.r};
    color: ${({ theme }) => theme.colors.text.high.white};
    caret-color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.brand.white.pressed};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }

  button {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);

    height: 100%;
  }
`
