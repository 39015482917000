import { useAccessToken } from '@/hooks/auth/use-access-token'
import { SESSION_MAX_AGE } from '@/types/auth'

import {
  getGetKacAuthProfileQueryKey,
  useGetKacAuthProfile,
} from '@shared/generated/api/fn/kac/auth/auth'

export const useProfile = () => {
  const { accessToken } = useAccessToken()
  const { data, isLoading: isPending } = useGetKacAuthProfile({
    request: {
      accessToken,
    },
    query: {
      enabled: !!accessToken,
      queryKey: getGetKacAuthProfileQueryKey(),
      gcTime: SESSION_MAX_AGE,
    },
  })

  return {
    data,
    isLoading: !data || isPending,
  }
}
