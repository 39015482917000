import { useMemo, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import styled from '@emotion/styled'

import { Icon } from '@shared/lib/components/common/atom/icon'
import { ICON_SVG } from '@shared/lib/constants/import/icon-svg'
import { LINK_TYPE, MENU } from '@/constants/menu'
import {
  HEADER_HEIGHT,
  MAX_WIDTH,
  Z_INDEX_VALUE,
} from '@/constants/style/layout'
import { useAccessToken } from '@/hooks/auth/use-access-token'
import { useProfile } from '@/hooks/auth/use-profile'
import { AuthDropDownMenu } from '@/layout/header/auth-drop-down-menu'
import { GnbMenu } from '@/layout/header/gnb-menu'
import { HamburgerMenu } from '@/layout/header/hamburger-menu'
import { SearchField } from '@/layout/header/search-field'
import { LayoutConfig } from '@/types/layout-config'
import { checkedTeacherWithAcademyTeacher } from '@/utils/auth'

interface HeaderProps {
  layoutConfig?: LayoutConfig
}
export const Header = ({ layoutConfig }: HeaderProps) => {
  const { accessToken, isLoading } = useAccessToken()

  const { status } = useSession()
  const { data: profile } = useProfile()
  const isTeacherWithAcademyTeacher = useMemo(() => {
    return checkedTeacherWithAcademyTeacher(profile?.Roles || [])
  }, [profile?.Roles])

  const router = useRouter()
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  const onHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen)
  }

  const openHamburgerMenu = () => {
    setIsHamburgerMenuOpen(true)
  }

  const closeHamburgerMenu = () => {
    setIsHamburgerMenuOpen(false)
  }

  return (
    <>
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderInnerRowContainer className="top">
            <LogoContainer>
              <Link
                href={'/'}
                passHref
              >
                <img
                  src={'/images/logo-white.svg'}
                  alt={'케아클 로고'}
                />
              </Link>
            </LogoContainer>
            <SearchContainer>
              <SearchField />
            </SearchContainer>

            <ActionContainer>
              <ul className="pc">
                <li>
                  {!accessToken && !isLoading && (
                    <Link
                      href={MENU.LOGIN.link.url}
                      passHref
                    >
                      <span>{MENU.LOGIN.name}</span>
                    </Link>
                  )}

                  {accessToken && !isLoading && <AuthDropDownMenu />}
                </li>
                <li>
                  {!isTeacherWithAcademyTeacher && (
                    <TeacherSupportButton
                      href={MENU.TEACHER_SUPPORT.link.url}
                      target={
                        MENU.TEACHER_SUPPORT.link.type === LINK_TYPE.SELF
                          ? '_self'
                          : '_blank'
                      }
                    >
                      {MENU.TEACHER_SUPPORT.name}
                    </TeacherSupportButton>
                  )}

                  {isTeacherWithAcademyTeacher && (
                    <TeachersRoomButton
                      href={MENU.TEACHERS_ROOM_SERVICE.link.url}
                      target={
                        MENU.TEACHERS_ROOM_SERVICE.link.type === LINK_TYPE.BLANK
                          ? '_blank'
                          : '_self'
                      }
                    >
                      <span>{MENU.TEACHERS_ROOM_SERVICE.name}</span>
                      <LinkIcon icon={ICON_SVG.LINE.SIZE_16.ICON_LINK} />
                    </TeachersRoomButton>
                  )}
                </li>
              </ul>

              <ul className="tablet tablet-sm mobile">
                <li>
                  <a>
                    <SearchIcon icon={ICON_SVG.LINE.SIZE_24.ICON_SEARCH} />
                  </a>
                </li>
                <li>
                  <a onClick={onHamburgerMenu}>
                    <HamburgerIcon
                      icon={ICON_SVG.LINE.SIZE_24.ICON_HAMBURGER}
                    />
                  </a>
                </li>
              </ul>
            </ActionContainer>
          </HeaderInnerRowContainer>
          <HeaderInnerRowContainer className="bottom">
            <GnbMenu />
          </HeaderInnerRowContainer>
        </HeaderInnerContainer>
      </HeaderContainer>

      <HamburgerMenu
        isHamburgerMenuOpen={isHamburgerMenuOpen}
        closeHamburgerMenu={closeHamburgerMenu}
      />
    </>
  )
}

const HeaderContainer = styled.header`
  position: fixed;
  z-index: ${Z_INDEX_VALUE.HEADER};
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${HEADER_HEIGHT.PC}px;
  background-color: ${({ theme }) => theme.colors.environment.background.on};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brand.white.disabled};

  ${({ theme }) => theme.media.tablet} {
    height: ${HEADER_HEIGHT.TABLET}px;
  }
`

const HeaderInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  height: 100%;
  padding: 18px 16px 0 16px;
`

const HeaderInnerRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &.top {
    height: 36px;

    display: flex;
    align-items: center;
    gap: 16px;
  }

  &.bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.tablet} {
    &.top {
      height: 24px;
    }

    &.bottom {
      display: none;
    }
  }
`

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: fill;
      width: 125.772px;
      height: 23.999px;
      flex-shrink: 0;
    }
  }

  ${({ theme }) => theme.media.tablet} {
  }
`

const SearchContainer = styled.div`
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`

const ActionContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;
    height: 100%;

    li {
      display: flex;
      align-items: center;

      padding: 8px 16px;

      &:last-of-type {
        padding: 8px 0 8px 16px;
      }

      a {
        display: flex;
        align-items: center;
        gap: 2px;

        width: 100%;
        span {
          height: 100%;
          display: flex;
          align-items: center;

          ${({ theme }) => theme.typography.body2.b};
          color: ${({ theme }) => theme.colors.text.high.white};
        }
      }
    }
  }

  ${({ theme }) => theme.media.tablet} {
    ul {
      gap: 8px;

      li {
        padding: 0;
        &:last-of-type {
          padding: 0;
        }

        a {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`

const SearchIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`

const HamburgerIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`

const LinkIcon = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`

const TeacherSupportButton = styled.a`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.icon.inactive.white};
  background: ${({ theme }) => theme.colors.brand.primary.default};

  ${({ theme }) => theme.typography.body2.b};

  color: ${({ theme }) => theme.colors.text.high.white};
`

const TeachersRoomButton = styled(TeacherSupportButton)``
