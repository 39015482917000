/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

/**
 * SNS 로그인 타입
 */
export type PostTeachersRoomAuthSignInAcademyAdminBodyProviderType =
  (typeof PostTeachersRoomAuthSignInAcademyAdminBodyProviderType)[keyof typeof PostTeachersRoomAuthSignInAcademyAdminBodyProviderType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostTeachersRoomAuthSignInAcademyAdminBodyProviderType = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
} as const
