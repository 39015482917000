/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'

import type { ErrorType } from '../../../config/axios-instance'
import { axiosInstance } from '../../../config/axios-instance'
import type {
  DeleteKacAuthUserWithdrawal400,
  DeleteKacAuthUserWithdrawal404,
  DeleteKacAuthUserWithdrawalBody,
  GetKacAuthProfile200,
  GetKacAuthProfile404,
  GetKacAuthTokenRefresh200,
  GetKacAuthTokenRefresh400,
  GetKacAuthTokenRefresh404,
  PatchKacAuthPassword400,
  PatchKacAuthPassword404,
  PatchKacAuthPasswordBody,
  PatchKacAuthProfileAgree200,
  PatchKacAuthProfileAgree400,
  PatchKacAuthProfileAgreeBody,
  PatchKacAuthProfilePassword400,
  PatchKacAuthProfilePasswordBody,
  PatchKacAuthProfilePhoneNumber200,
  PatchKacAuthProfilePhoneNumber400,
  PatchKacAuthProfilePhoneNumberBody,
  PostKacAuthProfileVerifyNumberSend200,
  PostKacAuthProfileVerifyNumberSend400,
  PostKacAuthProfileVerifyNumberSendBody,
  PostKacAuthResetPasswordCheck400,
  PostKacAuthResetPasswordCheck404,
  PostKacAuthResetPasswordCheckBody,
  PostKacAuthResetPasswordSend400,
  PostKacAuthResetPasswordSend404,
  PostKacAuthResetPasswordSendBody,
  PostKacAuthSignIn200,
  PostKacAuthSignIn400,
  PostKacAuthSignIn404,
  PostKacAuthSignInBody,
  PostKacAuthSignUp200,
  PostKacAuthSignUp400,
  PostKacAuthSignUpBody,
  PostKacAuthVerifyNumberCheck200,
  PostKacAuthVerifyNumberCheck400,
  PostKacAuthVerifyNumberCheck404,
  PostKacAuthVerifyNumberCheckBody,
  PostKacAuthVerifyNumberSend200,
  PostKacAuthVerifyNumberSend400,
  PostKacAuthVerifyNumberSendBody,
} from '../../../model'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * 로그인
 * @summary 로그인
 */
export const postKacAuthSignIn = (
  postKacAuthSignInBody: PostKacAuthSignInBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PostKacAuthSignIn200>(
    {
      url: `/kac/auth/sign-in`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthSignInBody,
    },
    options,
  )
}

export const getPostKacAuthSignInMutationOptions = <
  TError = ErrorType<PostKacAuthSignIn400 | PostKacAuthSignIn404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthSignIn>>,
    TError,
    { data: PostKacAuthSignInBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthSignIn>>,
  TError,
  { data: PostKacAuthSignInBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthSignIn>>,
    { data: PostKacAuthSignInBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthSignIn(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthSignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthSignIn>>
>
export type PostKacAuthSignInMutationBody = PostKacAuthSignInBody
export type PostKacAuthSignInMutationError = ErrorType<
  PostKacAuthSignIn400 | PostKacAuthSignIn404
>

/**
 * @summary 로그인
 */
export const usePostKacAuthSignIn = <
  TError = ErrorType<PostKacAuthSignIn400 | PostKacAuthSignIn404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthSignIn>>,
    TError,
    { data: PostKacAuthSignInBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthSignIn>>,
  TError,
  { data: PostKacAuthSignInBody },
  TContext
> => {
  const mutationOptions = getPostKacAuthSignInMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 토큰 재갱신
 * @summary 토큰 재갱신
 */
export const getKacAuthTokenRefresh = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GetKacAuthTokenRefresh200>(
    { url: `/kac/auth/token/refresh`, method: 'GET', signal },
    options,
  )
}

export const getGetKacAuthTokenRefreshQueryKey = () => {
  return [`/kac/auth/token/refresh`] as const
}

export const getGetKacAuthTokenRefreshQueryOptions = <
  TData = Awaited<ReturnType<typeof getKacAuthTokenRefresh>>,
  TError = ErrorType<GetKacAuthTokenRefresh400 | GetKacAuthTokenRefresh404>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthTokenRefresh>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetKacAuthTokenRefreshQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getKacAuthTokenRefresh>>
  > = ({ signal }) => getKacAuthTokenRefresh(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthTokenRefresh>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetKacAuthTokenRefreshQueryResult = NonNullable<
  Awaited<ReturnType<typeof getKacAuthTokenRefresh>>
>
export type GetKacAuthTokenRefreshQueryError = ErrorType<
  GetKacAuthTokenRefresh400 | GetKacAuthTokenRefresh404
>

/**
 * @summary 토큰 재갱신
 */
export const useGetKacAuthTokenRefresh = <
  TData = Awaited<ReturnType<typeof getKacAuthTokenRefresh>>,
  TError = ErrorType<GetKacAuthTokenRefresh400 | GetKacAuthTokenRefresh404>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthTokenRefresh>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKacAuthTokenRefreshQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 내 정보 조회하기
 * @summary 내 정보 조회하기
 */
export const getKacAuthProfile = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GetKacAuthProfile200>(
    { url: `/kac/auth/profile`, method: 'GET', signal },
    options,
  )
}

export const getGetKacAuthProfileQueryKey = () => {
  return [`/kac/auth/profile`] as const
}

export const getGetKacAuthProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getKacAuthProfile>>,
  TError = ErrorType<GetKacAuthProfile404>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthProfile>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetKacAuthProfileQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getKacAuthProfile>>
  > = ({ signal }) => getKacAuthProfile(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetKacAuthProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getKacAuthProfile>>
>
export type GetKacAuthProfileQueryError = ErrorType<GetKacAuthProfile404>

/**
 * @summary 내 정보 조회하기
 */
export const useGetKacAuthProfile = <
  TData = Awaited<ReturnType<typeof getKacAuthProfile>>,
  TError = ErrorType<GetKacAuthProfile404>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getKacAuthProfile>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKacAuthProfileQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 내 정보 수정 - 비밀번호 항목
 * @summary 내 정보 수정 - 비밀번호 항목
 */
export const patchKacAuthProfilePassword = (
  patchKacAuthProfilePasswordBody: PatchKacAuthProfilePasswordBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/kac/auth/profile/password`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchKacAuthProfilePasswordBody,
    },
    options,
  )
}

export const getPatchKacAuthProfilePasswordMutationOptions = <
  TError = ErrorType<PatchKacAuthProfilePassword400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfilePassword>>,
    TError,
    { data: PatchKacAuthProfilePasswordBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchKacAuthProfilePassword>>,
  TError,
  { data: PatchKacAuthProfilePasswordBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchKacAuthProfilePassword>>,
    { data: PatchKacAuthProfilePasswordBody }
  > = (props) => {
    const { data } = props ?? {}

    return patchKacAuthProfilePassword(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchKacAuthProfilePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchKacAuthProfilePassword>>
>
export type PatchKacAuthProfilePasswordMutationBody =
  PatchKacAuthProfilePasswordBody
export type PatchKacAuthProfilePasswordMutationError =
  ErrorType<PatchKacAuthProfilePassword400>

/**
 * @summary 내 정보 수정 - 비밀번호 항목
 */
export const usePatchKacAuthProfilePassword = <
  TError = ErrorType<PatchKacAuthProfilePassword400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfilePassword>>,
    TError,
    { data: PatchKacAuthProfilePasswordBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchKacAuthProfilePassword>>,
  TError,
  { data: PatchKacAuthProfilePasswordBody },
  TContext
> => {
  const mutationOptions = getPatchKacAuthProfilePasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 내 정보 수정 - 핸드폰번호 항목
 * @summary 내 정보 수정 - 핸드폰번호 항목
 */
export const patchKacAuthProfilePhoneNumber = (
  patchKacAuthProfilePhoneNumberBody: PatchKacAuthProfilePhoneNumberBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PatchKacAuthProfilePhoneNumber200>(
    {
      url: `/kac/auth/profile/phoneNumber`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchKacAuthProfilePhoneNumberBody,
    },
    options,
  )
}

export const getPatchKacAuthProfilePhoneNumberMutationOptions = <
  TError = ErrorType<PatchKacAuthProfilePhoneNumber400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>,
    TError,
    { data: PatchKacAuthProfilePhoneNumberBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>,
  TError,
  { data: PatchKacAuthProfilePhoneNumberBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>,
    { data: PatchKacAuthProfilePhoneNumberBody }
  > = (props) => {
    const { data } = props ?? {}

    return patchKacAuthProfilePhoneNumber(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchKacAuthProfilePhoneNumberMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>
>
export type PatchKacAuthProfilePhoneNumberMutationBody =
  PatchKacAuthProfilePhoneNumberBody
export type PatchKacAuthProfilePhoneNumberMutationError =
  ErrorType<PatchKacAuthProfilePhoneNumber400>

/**
 * @summary 내 정보 수정 - 핸드폰번호 항목
 */
export const usePatchKacAuthProfilePhoneNumber = <
  TError = ErrorType<PatchKacAuthProfilePhoneNumber400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>,
    TError,
    { data: PatchKacAuthProfilePhoneNumberBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchKacAuthProfilePhoneNumber>>,
  TError,
  { data: PatchKacAuthProfilePhoneNumberBody },
  TContext
> => {
  const mutationOptions =
    getPatchKacAuthProfilePhoneNumberMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 내 정보 수정 - 마케팅 수신 항목
 * @summary 내 정보 수정 - 마케팅 수신 항목
 */
export const patchKacAuthProfileAgree = (
  patchKacAuthProfileAgreeBody: PatchKacAuthProfileAgreeBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PatchKacAuthProfileAgree200>(
    {
      url: `/kac/auth/profile/agree`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchKacAuthProfileAgreeBody,
    },
    options,
  )
}

export const getPatchKacAuthProfileAgreeMutationOptions = <
  TError = ErrorType<PatchKacAuthProfileAgree400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfileAgree>>,
    TError,
    { data: PatchKacAuthProfileAgreeBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchKacAuthProfileAgree>>,
  TError,
  { data: PatchKacAuthProfileAgreeBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchKacAuthProfileAgree>>,
    { data: PatchKacAuthProfileAgreeBody }
  > = (props) => {
    const { data } = props ?? {}

    return patchKacAuthProfileAgree(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchKacAuthProfileAgreeMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchKacAuthProfileAgree>>
>
export type PatchKacAuthProfileAgreeMutationBody = PatchKacAuthProfileAgreeBody
export type PatchKacAuthProfileAgreeMutationError =
  ErrorType<PatchKacAuthProfileAgree400>

/**
 * @summary 내 정보 수정 - 마케팅 수신 항목
 */
export const usePatchKacAuthProfileAgree = <
  TError = ErrorType<PatchKacAuthProfileAgree400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthProfileAgree>>,
    TError,
    { data: PatchKacAuthProfileAgreeBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchKacAuthProfileAgree>>,
  TError,
  { data: PatchKacAuthProfileAgreeBody },
  TContext
> => {
  const mutationOptions = getPatchKacAuthProfileAgreeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 회원가입 인증 번호 전송
 * @summary 회원가입 인증 번호 전송
 */
export const postKacAuthVerifyNumberSend = (
  postKacAuthVerifyNumberSendBody: PostKacAuthVerifyNumberSendBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PostKacAuthVerifyNumberSend200>(
    {
      url: `/kac/auth/verify-number/send`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthVerifyNumberSendBody,
    },
    options,
  )
}

export const getPostKacAuthVerifyNumberSendMutationOptions = <
  TError = ErrorType<PostKacAuthVerifyNumberSend400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>,
    TError,
    { data: PostKacAuthVerifyNumberSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>,
  TError,
  { data: PostKacAuthVerifyNumberSendBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>,
    { data: PostKacAuthVerifyNumberSendBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthVerifyNumberSend(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthVerifyNumberSendMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>
>
export type PostKacAuthVerifyNumberSendMutationBody =
  PostKacAuthVerifyNumberSendBody
export type PostKacAuthVerifyNumberSendMutationError =
  ErrorType<PostKacAuthVerifyNumberSend400>

/**
 * @summary 회원가입 인증 번호 전송
 */
export const usePostKacAuthVerifyNumberSend = <
  TError = ErrorType<PostKacAuthVerifyNumberSend400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>,
    TError,
    { data: PostKacAuthVerifyNumberSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberSend>>,
  TError,
  { data: PostKacAuthVerifyNumberSendBody },
  TContext
> => {
  const mutationOptions = getPostKacAuthVerifyNumberSendMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 내 정보 수정 - 인증 번호 전송
 * @summary 내 정보 수정 - 인증 번호 전송
 */
export const postKacAuthProfileVerifyNumberSend = (
  postKacAuthProfileVerifyNumberSendBody: PostKacAuthProfileVerifyNumberSendBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PostKacAuthProfileVerifyNumberSend200>(
    {
      url: `/kac/auth/profile/verify-number/send`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthProfileVerifyNumberSendBody,
    },
    options,
  )
}

export const getPostKacAuthProfileVerifyNumberSendMutationOptions = <
  TError = ErrorType<PostKacAuthProfileVerifyNumberSend400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>,
    TError,
    { data: PostKacAuthProfileVerifyNumberSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>,
  TError,
  { data: PostKacAuthProfileVerifyNumberSendBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>,
    { data: PostKacAuthProfileVerifyNumberSendBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthProfileVerifyNumberSend(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthProfileVerifyNumberSendMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>
>
export type PostKacAuthProfileVerifyNumberSendMutationBody =
  PostKacAuthProfileVerifyNumberSendBody
export type PostKacAuthProfileVerifyNumberSendMutationError =
  ErrorType<PostKacAuthProfileVerifyNumberSend400>

/**
 * @summary 내 정보 수정 - 인증 번호 전송
 */
export const usePostKacAuthProfileVerifyNumberSend = <
  TError = ErrorType<PostKacAuthProfileVerifyNumberSend400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>,
    TError,
    { data: PostKacAuthProfileVerifyNumberSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthProfileVerifyNumberSend>>,
  TError,
  { data: PostKacAuthProfileVerifyNumberSendBody },
  TContext
> => {
  const mutationOptions =
    getPostKacAuthProfileVerifyNumberSendMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 회원가입 인증 번호 확인
 * @summary 회원가입 인증 번호 확인
 */
export const postKacAuthVerifyNumberCheck = (
  postKacAuthVerifyNumberCheckBody: PostKacAuthVerifyNumberCheckBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PostKacAuthVerifyNumberCheck200>(
    {
      url: `/kac/auth/verify-number/check`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthVerifyNumberCheckBody,
    },
    options,
  )
}

export const getPostKacAuthVerifyNumberCheckMutationOptions = <
  TError = ErrorType<
    PostKacAuthVerifyNumberCheck400 | PostKacAuthVerifyNumberCheck404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>,
    TError,
    { data: PostKacAuthVerifyNumberCheckBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>,
  TError,
  { data: PostKacAuthVerifyNumberCheckBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>,
    { data: PostKacAuthVerifyNumberCheckBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthVerifyNumberCheck(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthVerifyNumberCheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>
>
export type PostKacAuthVerifyNumberCheckMutationBody =
  PostKacAuthVerifyNumberCheckBody
export type PostKacAuthVerifyNumberCheckMutationError = ErrorType<
  PostKacAuthVerifyNumberCheck400 | PostKacAuthVerifyNumberCheck404
>

/**
 * @summary 회원가입 인증 번호 확인
 */
export const usePostKacAuthVerifyNumberCheck = <
  TError = ErrorType<
    PostKacAuthVerifyNumberCheck400 | PostKacAuthVerifyNumberCheck404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>,
    TError,
    { data: PostKacAuthVerifyNumberCheckBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthVerifyNumberCheck>>,
  TError,
  { data: PostKacAuthVerifyNumberCheckBody },
  TContext
> => {
  const mutationOptions =
    getPostKacAuthVerifyNumberCheckMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 회원가입
 * @summary 회원가입
 */
export const postKacAuthSignUp = (
  postKacAuthSignUpBody: PostKacAuthSignUpBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PostKacAuthSignUp200>(
    {
      url: `/kac/auth/sign-up`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthSignUpBody,
    },
    options,
  )
}

export const getPostKacAuthSignUpMutationOptions = <
  TError = ErrorType<PostKacAuthSignUp400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthSignUp>>,
    TError,
    { data: PostKacAuthSignUpBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthSignUp>>,
  TError,
  { data: PostKacAuthSignUpBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthSignUp>>,
    { data: PostKacAuthSignUpBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthSignUp(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthSignUp>>
>
export type PostKacAuthSignUpMutationBody = PostKacAuthSignUpBody
export type PostKacAuthSignUpMutationError = ErrorType<PostKacAuthSignUp400>

/**
 * @summary 회원가입
 */
export const usePostKacAuthSignUp = <
  TError = ErrorType<PostKacAuthSignUp400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthSignUp>>,
    TError,
    { data: PostKacAuthSignUpBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthSignUp>>,
  TError,
  { data: PostKacAuthSignUpBody },
  TContext
> => {
  const mutationOptions = getPostKacAuthSignUpMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 비밀번호 재설정 전송
 * @summary 비밀번호 재설정 전송
 */
export const postKacAuthResetPasswordSend = (
  postKacAuthResetPasswordSendBody: PostKacAuthResetPasswordSendBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/kac/auth/reset-password/send`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthResetPasswordSendBody,
    },
    options,
  )
}

export const getPostKacAuthResetPasswordSendMutationOptions = <
  TError = ErrorType<
    PostKacAuthResetPasswordSend400 | PostKacAuthResetPasswordSend404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>,
    TError,
    { data: PostKacAuthResetPasswordSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>,
  TError,
  { data: PostKacAuthResetPasswordSendBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>,
    { data: PostKacAuthResetPasswordSendBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthResetPasswordSend(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthResetPasswordSendMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>
>
export type PostKacAuthResetPasswordSendMutationBody =
  PostKacAuthResetPasswordSendBody
export type PostKacAuthResetPasswordSendMutationError = ErrorType<
  PostKacAuthResetPasswordSend400 | PostKacAuthResetPasswordSend404
>

/**
 * @summary 비밀번호 재설정 전송
 */
export const usePostKacAuthResetPasswordSend = <
  TError = ErrorType<
    PostKacAuthResetPasswordSend400 | PostKacAuthResetPasswordSend404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>,
    TError,
    { data: PostKacAuthResetPasswordSendBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthResetPasswordSend>>,
  TError,
  { data: PostKacAuthResetPasswordSendBody },
  TContext
> => {
  const mutationOptions =
    getPostKacAuthResetPasswordSendMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 비밀번호 재설정 검증
 * @summary 비밀번호 재설정 검증
 */
export const postKacAuthResetPasswordCheck = (
  postKacAuthResetPasswordCheckBody: PostKacAuthResetPasswordCheckBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/kac/auth/reset-password/check`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postKacAuthResetPasswordCheckBody,
    },
    options,
  )
}

export const getPostKacAuthResetPasswordCheckMutationOptions = <
  TError = ErrorType<
    PostKacAuthResetPasswordCheck400 | PostKacAuthResetPasswordCheck404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>,
    TError,
    { data: PostKacAuthResetPasswordCheckBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>,
  TError,
  { data: PostKacAuthResetPasswordCheckBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>,
    { data: PostKacAuthResetPasswordCheckBody }
  > = (props) => {
    const { data } = props ?? {}

    return postKacAuthResetPasswordCheck(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostKacAuthResetPasswordCheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>
>
export type PostKacAuthResetPasswordCheckMutationBody =
  PostKacAuthResetPasswordCheckBody
export type PostKacAuthResetPasswordCheckMutationError = ErrorType<
  PostKacAuthResetPasswordCheck400 | PostKacAuthResetPasswordCheck404
>

/**
 * @summary 비밀번호 재설정 검증
 */
export const usePostKacAuthResetPasswordCheck = <
  TError = ErrorType<
    PostKacAuthResetPasswordCheck400 | PostKacAuthResetPasswordCheck404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>,
    TError,
    { data: PostKacAuthResetPasswordCheckBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postKacAuthResetPasswordCheck>>,
  TError,
  { data: PostKacAuthResetPasswordCheckBody },
  TContext
> => {
  const mutationOptions =
    getPostKacAuthResetPasswordCheckMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 유저 비밀번호 변경
 * @summary 유저 비밀번호 변경
 */
export const patchKacAuthPassword = (
  patchKacAuthPasswordBody: PatchKacAuthPasswordBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/kac/auth/password`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchKacAuthPasswordBody,
    },
    options,
  )
}

export const getPatchKacAuthPasswordMutationOptions = <
  TError = ErrorType<PatchKacAuthPassword400 | PatchKacAuthPassword404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthPassword>>,
    TError,
    { data: PatchKacAuthPasswordBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchKacAuthPassword>>,
  TError,
  { data: PatchKacAuthPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchKacAuthPassword>>,
    { data: PatchKacAuthPasswordBody }
  > = (props) => {
    const { data } = props ?? {}

    return patchKacAuthPassword(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchKacAuthPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchKacAuthPassword>>
>
export type PatchKacAuthPasswordMutationBody = PatchKacAuthPasswordBody
export type PatchKacAuthPasswordMutationError = ErrorType<
  PatchKacAuthPassword400 | PatchKacAuthPassword404
>

/**
 * @summary 유저 비밀번호 변경
 */
export const usePatchKacAuthPassword = <
  TError = ErrorType<PatchKacAuthPassword400 | PatchKacAuthPassword404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchKacAuthPassword>>,
    TError,
    { data: PatchKacAuthPasswordBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchKacAuthPassword>>,
  TError,
  { data: PatchKacAuthPasswordBody },
  TContext
> => {
  const mutationOptions = getPatchKacAuthPasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 회원 탈퇴
 * @summary 회원 탈퇴
 */
export const deleteKacAuthUserWithdrawal = (
  deleteKacAuthUserWithdrawalBody: DeleteKacAuthUserWithdrawalBody,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<boolean>(
    {
      url: `/kac/auth/user/withdrawal`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteKacAuthUserWithdrawalBody,
    },
    options,
  )
}

export const getDeleteKacAuthUserWithdrawalMutationOptions = <
  TError = ErrorType<
    DeleteKacAuthUserWithdrawal400 | DeleteKacAuthUserWithdrawal404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>,
    TError,
    { data: DeleteKacAuthUserWithdrawalBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>,
  TError,
  { data: DeleteKacAuthUserWithdrawalBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>,
    { data: DeleteKacAuthUserWithdrawalBody }
  > = (props) => {
    const { data } = props ?? {}

    return deleteKacAuthUserWithdrawal(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteKacAuthUserWithdrawalMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>
>
export type DeleteKacAuthUserWithdrawalMutationBody =
  DeleteKacAuthUserWithdrawalBody
export type DeleteKacAuthUserWithdrawalMutationError = ErrorType<
  DeleteKacAuthUserWithdrawal400 | DeleteKacAuthUserWithdrawal404
>

/**
 * @summary 회원 탈퇴
 */
export const useDeleteKacAuthUserWithdrawal = <
  TError = ErrorType<
    DeleteKacAuthUserWithdrawal400 | DeleteKacAuthUserWithdrawal404
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>,
    TError,
    { data: DeleteKacAuthUserWithdrawalBody },
    TContext
  >
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteKacAuthUserWithdrawal>>,
  TError,
  { data: DeleteKacAuthUserWithdrawalBody },
  TContext
> => {
  const mutationOptions = getDeleteKacAuthUserWithdrawalMutationOptions(options)

  return useMutation(mutationOptions)
}
