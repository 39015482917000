/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type GetKacTeacher200Status =
  (typeof GetKacTeacher200Status)[keyof typeof GetKacTeacher200Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetKacTeacher200Status = {
  APPLY: 'APPLY',
  REVIEW: 'REVIEW',
  ACTIVE: 'ACTIVE',
  REJECT: 'REJECT',
  PAUSE: 'PAUSE',
} as const
