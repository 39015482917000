import Link from 'next/link'
import classNames from 'classnames'
import styled from '@emotion/styled'

import { Icon } from '@shared/lib/components/common/atom/icon'
import { ICON_SVG } from '@shared/lib/constants/import/icon-svg'
import { LINK_TYPE, MENU, Menu } from '@/constants/menu'
import { logout } from '@/utils/auth'

interface GnbMenuItemProps {
  main: Menu
  index: number
}

export const GnbMenuItem = ({ main, index }: GnbMenuItemProps) => {
  const onLogout = async () => {
    await logout({
      redirect: true,
    })
  }

  return (
    <li
      className={classNames('main', {
        ['is-sub']: !!main.child,
      })}
      key={`main-menu-${index}`}
    >
      {!!main.child && (
        <>
          <a
            className="main"
            title={main.name}
          >
            <span className="main">{main.name}</span>
            <MenuArrowIcon icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_DOWN} />
          </a>

          <ul
            className={classNames(
              {
                first: index === 0,
              },
              'sub',
            )}
          >
            {main.child?.map((sub, subIndex) => {
              const isLogout = sub.name === MENU.LOGOUT.name

              if (isLogout) {
                return (
                  <a
                    key={`sub-menu-${subIndex}`}
                    onClick={onLogout}
                    title={sub.name}
                  >
                    <span className="sub">{sub.name}</span>
                  </a>
                )
              } else {
                return (
                  <li
                    className="sub"
                    key={`sub-menu-${subIndex}`}
                  >
                    <Link
                      href={sub.link.url}
                      target={
                        sub.link.type === LINK_TYPE.SELF ? '_self' : '_blank'
                      }
                      title={sub.name}
                      passHref
                    >
                      <span className="sub">{sub.name}</span>
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </>
      )}

      {!main.child && (
        <Link
          href={main.link.url}
          target={main.link.type === LINK_TYPE.SELF ? '_self' : '_blank'}
          title={main.name}
          passHref
        >
          <span className="main">{main.name}</span>
        </Link>
      )}
    </li>
  )
}

const MenuArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`
