/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostTeachersRoomAuthSignInAcademyAdmin404Code =
  (typeof PostTeachersRoomAuthSignInAcademyAdmin404Code)[keyof typeof PostTeachersRoomAuthSignInAcademyAdmin404Code]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostTeachersRoomAuthSignInAcademyAdmin404Code = {
  E00: 'E00',
  E01: 'E01',
  E02: 'E02',
  E03: 'E03',
  E04: 'E04',
} as const
