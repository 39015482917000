/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostKacAuthVerifyNumberCheckBodyType =
  (typeof PostKacAuthVerifyNumberCheckBodyType)[keyof typeof PostKacAuthVerifyNumberCheckBodyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostKacAuthVerifyNumberCheckBodyType = {
  SIGNUP: 'SIGNUP',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
} as const
