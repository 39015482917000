/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostKacAuthSignIn404UserProviderType =
  (typeof PostKacAuthSignIn404UserProviderType)[keyof typeof PostKacAuthSignIn404UserProviderType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostKacAuthSignIn404UserProviderType = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
} as const
