/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type GetKacAuthProfile200RolesItemRole =
  (typeof GetKacAuthProfile200RolesItemRole)[keyof typeof GetKacAuthProfile200RolesItemRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetKacAuthProfile200RolesItemRole = {
  USER: 'USER',
  TEACHER: 'TEACHER',
  ACADEMY_TEACHER: 'ACADEMY_TEACHER',
  ACADEMY_ADMIN: 'ACADEMY_ADMIN',
  ADMIN: 'ADMIN',
} as const
