import { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import Link from 'next/link'
import classNames from 'classnames'
import styled from '@emotion/styled'

import { Icon } from '@shared/lib/components/common/atom/icon'
import { ICON_SVG } from '@shared/lib/constants/import/icon-svg'
import { MY_MENUS } from '@/constants/menu'
import { Z_INDEX_VALUE } from '@/constants/style/layout'
import { useProfile } from '@/hooks/auth/use-profile'
import { logout } from '@/utils/auth'

export const AuthDropDownMenu = () => {
  const { data: profile, isLoading } = useProfile()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const onClosePopover = () => {
    setIsPopoverOpen(false)
  }

  const onOpenWithClosePopover = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const onLogout = async () => {
    await logout({
      redirect: true,
    })
  }

  return (
    <AuthDropDownMenuContainer>
      <Popover
        containerStyle={{
          zIndex: `${Z_INDEX_VALUE.HEADER_DROP_DOWN_CONTAINER}`,
        }}
        isOpen={isPopoverOpen}
        positions={'bottom'}
        align={'center'}
        padding={8}
        onClickOutside={onClosePopover}
        content={
          <DropDownMenuContainer>
            {MY_MENUS.child?.map((menu, index) => {
              return (
                <li key={`my-menu-${index}`}>
                  <Link
                    href={menu.link.url}
                    passHref
                  >
                    {menu.name}
                  </Link>
                </li>
              )
            })}
            <li>
              <a onClick={onLogout}>로그아웃</a>
            </li>
          </DropDownMenuContainer>
        }
      >
        {isLoading ? (
          <></>
        ) : (
          <a
            className="user"
            onClick={onOpenWithClosePopover}
          >
            <img
              src={'/images/avatar.svg'}
              alt={'아바타'}
            />
            <span>{profile?.name}</span>
            <MenuArrowIcon
              className={classNames({
                active: isPopoverOpen,
              })}
              icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_UP}
            />

            <MenuArrowIcon
              className={classNames({
                active: !isPopoverOpen,
              })}
              icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_DOWN}
            />
          </a>
        )}
      </Popover>
    </AuthDropDownMenuContainer>
  )
}

const AuthDropDownMenuContainer = styled.div`
  a {
    &.user {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 2px;

      span {
        margin-left: 2px;
      }
    }
  }
`

const DropDownMenuContainer = styled.ul`
  border-radius: 8px;
  display: flex;
  width: 104px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  background-color: ${({ theme }) => theme.colors.brand.secondary.default};

  li {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.4px;

    color: ${({ theme }) => theme.colors.text.high.white};

    a {
      color: ${({ theme }) => theme.colors.text.high.white};
    }
  }
`

const MenuArrowIcon = styled(Icon)`
  display: none;
  width: 16px;
  height: 16px;

  &.active {
    display: flex;
  }

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`
