/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostKacTeacherApply400Code =
  (typeof PostKacTeacherApply400Code)[keyof typeof PostKacTeacherApply400Code]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostKacTeacherApply400Code = {
  E00: 'E00',
  E01: 'E01',
} as const
