import { AppleProfile } from 'next-auth/providers/apple'
import { FacebookProfile } from 'next-auth/providers/facebook'
import { GoogleProfile } from 'next-auth/providers/google'
import { KakaoProfile } from 'next-auth/providers/kakao'
import { NaverProfile } from 'next-auth/providers/naver'
import { signOut } from 'next-auth/react'

import {
  type GetKacAuthProfile200RolesItem,
  GetKacAuthProfile200RolesItemRole,
  PostKacAuthSignInBodyProviderType,
} from '@shared/generated/api/model'

export const logout = async ({
  callbackUrl = '',
  redirect = false,
}: {
  callbackUrl?: string
  redirect?: boolean
} = {}) => {
  const result = await signOut({
    redirect: false,
  })

  if (redirect) {
    window.location.replace(callbackUrl || '/')
    return
  }

  return result
}

interface ProviderProfile {
  name: string
  email: string
}

interface ProviderProfileParams {
  providerType: PostKacAuthSignInBodyProviderType
  profile: any
}

/**
 * 소셜 로그인 별 프로필 정보 가져오기
 * @param providerType
 * @param profile
 */
export const getProviderProfile = ({
  providerType,
  profile,
}: ProviderProfileParams) => {
  //네이버
  if (providerType === PostKacAuthSignInBodyProviderType.NAVER) {
    const naverProfile = profile as NaverProfile
    if (naverProfile.response) {
      const { name, email } = naverProfile.response
      return {
        name,
        email,
      }
    }
    //카카오
  } else if (providerType === PostKacAuthSignInBodyProviderType.KAKAO) {
    const kakaoProfile = profile as KakaoProfile
    if (kakaoProfile.kakao_account) {
      const { email, name } = kakaoProfile.kakao_account
      return {
        name,
        email,
      }
    }
    //구글
  } else if (providerType === PostKacAuthSignInBodyProviderType.GOOGLE) {
    const googleProfile = profile as GoogleProfile
    if (googleProfile) {
      const { email, name } = googleProfile
      return {
        name,
        email,
      }
    }
  } else if (providerType === PostKacAuthSignInBodyProviderType.FACEBOOK) {
    const facebookProfile = profile as FacebookProfile
  } else if (providerType === PostKacAuthSignInBodyProviderType.APPLE) {
    const appleProfile = profile as AppleProfile
    if (appleProfile) {
      const { email } = appleProfile
      return {
        email,
      }
    }
  }
}

export const checkedTeacherWithAcademyTeacher = (
  roles: GetKacAuthProfile200RolesItem[],
) => {
  for (let i = 0; i < roles.length; i++) {
    const { role } = roles[i]
    if (
      role === GetKacAuthProfile200RolesItemRole.TEACHER ||
      role === GetKacAuthProfile200RolesItemRole.ACADEMY_TEACHER
    ) {
      return true
    }
  }

  return false
}
