import Link from 'next/link'
import classNames from 'classnames'
import styled from '@emotion/styled'

import { FOOTER_MENUS, LINK_TYPE } from '@/constants/menu'
import { MAX_WIDTH } from '@/constants/style/layout'
import { CHANNEL_TALK_URL, SNS_URL } from '@/constants/url'
import { LayoutConfig } from '@/types/layout-config'

const SNS_LIST = [
  {
    name: '유튜브',
    link: SNS_URL.YOUTUBE,
    iconSrc: '/images/layout/youtube.svg',
  },
  {
    name: '인스타그램',
    link: SNS_URL.INSTAGRAM,
    iconSrc: '/images/layout/insta.svg',
  },
  {
    name: '네이버블로그',
    link: SNS_URL.BLOG,
    iconSrc: '/images/layout/blog.svg',
  },
  {
    name: '카카오톡',
    link: SNS_URL.KAKAO,
    iconSrc: '/images/layout/kakao.svg',
  },
]

interface FooterProps {
  layoutConfig?: LayoutConfig
}
export const Footer = ({ layoutConfig }: FooterProps) => {
  return (
    <FooterContainer
      className={classNames({ disabled: layoutConfig?.isDisableFooter })}
    >
      <FooterInnerContainer>
        <FooterInnerRowContainer className="top">
          <FooterLogoWithCsContainer>
            <Logo>
              <img
                src={'/images/logo-white.svg'}
                alt="케아클 로고"
              />
            </Logo>
            <Support>
              <SupportItem
                href={CHANNEL_TALK_URL}
                target={'_blank'}
              >
                채팅상담
              </SupportItem>
              <SupportItem onClick={() => {}}>1:1 게시판</SupportItem>
            </Support>
            <Comment>
              <CommentTitle>고객센터</CommentTitle>
              <CommentContent>*평일 10시~19시 (점심 12시~13시)</CommentContent>
            </Comment>
          </FooterLogoWithCsContainer>
          <FooterMenusContainer>
            <ul className="main">
              {FOOTER_MENUS.map((menu, index) => {
                return (
                  <li key={`footer-menu-${index}`}>
                    <span className="main">{menu.name}</span>

                    <ul className="sub">
                      {menu.child?.map((sub, subIndex) => {
                        return (
                          <li
                            key={`footer-sub-menu-${subIndex}`}
                            className="sub"
                          >
                            <Link
                              passHref
                              href={sub.link.url}
                              target={
                                sub.link.type === LINK_TYPE.BLANK
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <span className="sub">{sub.name}</span>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
              <li>
                <span className="main">언어</span>

                <ul className="sub">
                  <li className="sub">
                    <a>
                      <span className="sub">한국어</span>
                    </a>
                  </li>
                  <li className="sub">
                    <a>
                      <span className="sub">English</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </FooterMenusContainer>
        </FooterInnerRowContainer>
        <FooterInnerRowContainer className="middle">
          <SnsContainer>
            {SNS_LIST.map((sns, index) => {
              return (
                <SnsItem key={`sns-item-${index}`}>
                  <Link
                    target={'_blank'}
                    href={sns.link}
                    passHref
                  >
                    <img
                      src={sns.iconSrc}
                      alt={sns.name}
                    />
                  </Link>
                </SnsItem>
              )
            })}
          </SnsContainer>
        </FooterInnerRowContainer>
        <FooterInnerRowContainer className="bottom">
          <p className="pc tablet">
            <span>(주)케이에이씨미디어그룹 대표 김범조</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
            <span>서울특별시 서대문구 경기대로22</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
            <span>사업자등록번호 263-87-01955</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
            <span>통신판매업신고번호 제 2021-서울서대문-1425호</span>
          </p>

          <p className="tablet-sm mobile">
            <span>(주)케이에이씨미디어그룹 대표 김범조</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>서울특별시 서대문구 경기대로22</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>사업자등록번호 263-87-01955</span>
            <img
              src={'/images/layout/｜.svg'}
              alt={'divider-icon'}
            />
          </p>
          <p className="tablet-sm mobile">
            <span>통신판매업신고번호 제 2021-서울서대문-1425호</span>
          </p>

          <p className="pc tablet tablet-sm mobile last-word">
            (주)케이에이씨미디어그룹은 통신판매중개자로서 통신판매의 당사자가
            아니며, 구매자에게 강사가 등록한 상품/강의 정보 및 거래에 관한
            의무와 책임을 지지 않습니다.
          </p>
        </FooterInnerRowContainer>
      </FooterInnerContainer>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;

  padding: 40px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.brand.secondary.default};
`

const FooterInnerContainer = styled.div`
  padding: 0 16px;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;

  ${({ theme }) => theme.media.tablet} {
  }
`

const FooterInnerRowContainer = styled.div`
  width: 100%;

  &.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.middle {
  }

  &.bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 4px;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
      letter-spacing: -0.12px;
      color: ${({ theme }) => theme.colors.text.disabled.white};

      &.last-word {
        ${({ theme }) => theme.typography.body3.m};
        color: ${({ theme }) => theme.colors.text.disabled.white};
      }
    }
  }

  ${({ theme }) => theme.media.tablet} {
    &.top {
      align-items: flex-start;
      flex-direction: column;
      gap: 40px;
    }
  }

  ${({ theme }) => theme.media.tabletSm} {
  }
`

const FooterLogoWithCsContainer = styled.div``

const Logo = styled.div`
  img {
    width: 125.772px;
    height: 23.999px;
    flex-shrink: 0;
  }
`

const Support = styled.div`
  margin-top: 24px;

  display: flex;
  align-items: center;
  gap: 8px;
`

const SupportItem = styled.a`
  cursor: pointer;
  display: flex;
  width: 110px;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.icon.inactive.white};
  background: ${({ theme }) => theme.colors.brand.secondary.default};

  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`

const Comment = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const CommentTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.high.white};
`

const CommentContent = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
  letter-spacing: -0.4px;

  color: ${({ theme }) => theme.colors.text.medium.white};
`

const FooterMenusContainer = styled.div`
  ul {
    display: flex;

    &.main {
      gap: 32px;
    }

    &.sub {
      margin-top: 16px;
      flex-direction: column;
      gap: 16px;
    }

    li {
      span {
        &.main {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 14px */
          letter-spacing: -0.4px;
          color: ${({ theme }) => theme.colors.text.high.white};
        }
      }

      a {
        span {
          &.sub {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14px */
            letter-spacing: -0.4px;
            color: ${({ theme }) => theme.colors.text.medium.white};
          }
        }
      }
    }
  }

  ${({ theme }) => theme.media.tabletSm} {
    ul {
      &.main {
        display: grid;
        grid-template-columns: 100px 100px;
        gap: 32px;
      }
    }
  }
`

const SnsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const SnsItem = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`
