/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * 케아클 API
 * 케아클 Api DOC
 * OpenAPI spec version: 0.1.0
 */

export type PostTeachersRoomAuthSignInTeacher404UserProviderType =
  (typeof PostTeachersRoomAuthSignInTeacher404UserProviderType)[keyof typeof PostTeachersRoomAuthSignInTeacher404UserProviderType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostTeachersRoomAuthSignInTeacher404UserProviderType = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
} as const
